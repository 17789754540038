<template>
  <div class="inviteCode">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">绑定邀请码</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="bindTitle">绑定邀请码</div>
      <div class="tip">邀请码只能绑定一次 且不能修改</div>
      <van-field
        @blur="changBlur"
        class="inviteCodeInput"
        v-model="inviteCode"
        :border="false"
        label="邀请码"
        placeholder="请输入邀请码 (字母大写)"
      />
      <div class="inviteTip">你和邀请人各获得1天VIP</div>

      <div
        class="bindBtn"
        :class="{ isDisable: !inviteCode }"
        @click="bindCode"
      >
        立即绑定
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Loading, Toast } from "vant";
import { bindInviteCode } from "@/api/user";
export default {
  components: {
    [Field.name]: Field,
    // [Loading.name]: Loading,
  },
  data() {
    return {
      inviteCode: "",
    };
  },
  methods: {
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 绑定邀请码
    async bindCode() {
      if (!this.inviteCode) {
        Toast("请填写邀请码");
        return;
      }

      let req = {
        promotionCode: this.inviteCode,
      };
      let res = await this.$Api(bindInviteCode, req);
      if (res.code === 200) {
        this.$router.go(-1);
        Toast("邀请码绑定成功");
      } else {
        Toast(res.tip || "绑定失败请稍后再试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inviteCode {
  height: 100%;

  // background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #f3f3f3;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 0 28px;
    box-sizing: border-box;

    .bindTitle {
      margin-top: 31px;
      font-size: 30px;
      font-weight: 500;
      color: #333;
    }

    .tip {
      font-size: 16px;
      margin-top: 10px;
      color: #666;
    }

    .inviteCodeInput {
      padding: 0 0 12px;
      margin-top: 71px;
      box-sizing: border-box;
      background: #00000000;
      border-bottom: 1px solid #e6e6e6;
      /deep/ .van-field__label {
        width: 50px;
        margin-right: 16px;
        font-size: 15px;
        color: #000;
        display: flex;
        align-items: flex-end;
      }
      /deep/ .van-field__control {
        font-size: 14px;
        color: #000;
      }
      ::placeholder {
        color: #999999;
        font-size: 15px;
        line-height: 29px;
      }
    }
  }
  .inviteTip {
    color: #999999;
    font-size: 14px;
    margin-top: 10px;
  }
  .bindBtn {
    width: 284px;
    height: 44px;
    border-radius: 44px;
    line-height: 44px;
    text-align: center;
    margin: 60px auto 44px;
    font-size: 18px;
    color: #424242;
    background: #fbd13d;
    // background: #abaaba;
  }
  .isDisable {
    background: #abaaba;
  }
}
</style>
